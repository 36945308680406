import { query } from 'gql-query-builder';
import { gql } from '@apollo/client';
import { compareObjects } from '@lib/query-utils';
import { INITIAL_PARAMS } from '@lib/constants';
import { SEARCH_VERSION } from '@lib/version';
import { graphql, fetchGraphQL } from '@lib/api';
import { deepClone } from '@lib/utils';

/**
 *
 */
export default class Search {
  constructor() {
    this.skip = INITIAL_PARAMS.skip;
    this.sortBy = INITIAL_PARAMS.sortBy;
    this.take = INITIAL_PARAMS.take;
  }
  /**
   * Get non-default params and run query
   * @param Object all params to be included on the query
   * queryType: to dictate the fields that will be returned
   * optional: skip, take, sortBy to sort the results
   * @returns Object
   */
  async perform({ params, skip, take, sortBy, queryType = 'resultsPage' }) {
    const uniqParams = compareObjects({ ...INITIAL_PARAMS, ...params }, INITIAL_PARAMS);
    const where = this.buildConditions(uniqParams);

    const { query, variables } = this.buildQuery({
      queryType: queryType,
      skip: skip || this.skip,
      sortBy: sortBy || this.sortBy,
      take: take || this.take,
      where: where
    });

    // Perform the query based on the queryType
    if (queryType === 'ogMetaResults') {
      // Directly use fetchGraphQL for 'ogMetaResults'
      const data = await fetchGraphQL(query, variables);
      return data;
    } else {
      const q = gql`
        ${query}
      `;
      const data = await graphql(q, variables);

      return data;
    }
  }

  /**
   * Get the string for the saved graphQL search without making the query
   * Maintains the location object to reconcile location with state later
   * @param Object all params to be included on the query
   * queryType: to dictate the fields that will be returned
   * optional: skip, take, sortBy to sort the results
   * @returns Object
   */
  saveSearchString({ params, skip, take, sortBy, queryType = 'resultsPage' }) {
    const searchParams = deepClone(params);
    delete searchParams.propertySubTypeIds;

    const uniqParams = compareObjects({ ...INITIAL_PARAMS, ...searchParams }, INITIAL_PARAMS);
    const where = this.buildConditions(uniqParams);

    // if provided, insert property(sub)type values back into the saved search string
    if (searchParams.propertySubSelectIds)
      where.and.push({ propertySubSelectIds: { in: searchParams.propertySubSelectIds } });
    if (params.propertyTypeId)
      where.and.push({ propertyTypeId: { eq: searchParams.propertyTypeId } });

    const { variables } = this.buildQuery({
      queryType: queryType,
      skip: skip || this.skip,
      sortBy: sortBy || this.sortBy,
      take: take || this.take,
      where: where
    });

    variables.version = SEARCH_VERSION;

    return JSON.stringify(variables);
  }

  /**
   *
   * @param {*} param
   * @returns
   */
  buildQuery({ where, skip, sortBy, take, queryType = 'resultsPage' }) {
    const sortValue = sortBy || this.sortBy;
    const sortArray = Array.isArray(sortValue) ? sortValue : [sortValue];

    const fieldForQueryType = {
      advancedSearch: ['listingId'],
      resultsPage: [
        { agents: ['isActiveSibcyAgent'] },
        { alerts: ['type', 'text'] },
        'alertPills',
        'listingId',
        'listingUrl',
        'partBathroomCount',
        'fullBathroomCount',
        'canonicalUrl',
        'bathroomCount',
        'bedroomCount',
        'daysSinceNew',
        'daysSinceLastPriceChange',
        'hasInRangeOpenHouse',
        'isNewConstruction',
        'partBathroomCount',
        'priceFormatted',
        'status',
        { mlsCopyright: ['logoUrl', 'description'] },
        'acres',
        'address',
        'city',
        'state',
        'zip',
        { photos: ['midSizeImageUrl'] },
        { mainPhoto: ['midSizeImageUrl'] },
        'primaryAgentDisplayOne',
        'secondaryAgentDisplayOne',
        'isIdxListing'
      ],
      ogMetaResults: [{ mainPhoto: ['midSizeImageUrl'] }]
    };
    const items = fieldForQueryType[queryType];

    return query(
      {
        fields: ['totalCount', { items }],
        operation: 'listingSearch',
        variables: {
          order: { type: '[ListingSortInput!]', value: sortArray },
          skip: skip || this.skip,
          take: take || this.take,
          where: {
            type: 'ListingFilterInput',
            value: where
          }
        }
      },
      null,
      {
        operationName: 'ListingsQuery'
      }
    );
  }
  /**
   *
   * @param {*} params
   * @returns
   */
  buildConditions(params) {
    const operators = {
      and_since_new: ['daysSinceNew'],
      and_since_sold: ['daysSinceSold'],
      and_since_price_change: ['daysSinceLastPriceChange'],
      and_is_featured: ['featured'],
      contains: ['address'],
      eq: [
        'hasCentralAir',
        'hasFamilyRoom',
        'hasFormalDiningRoom',
        'hasLevel1Bath',
        'hasLevel1Bedroom',
        'hasSwimmingPool',
        'propertyTypeId'
      ],
      gte: ['bathrooms', 'bedrooms', 'garageSpaces', 'minAcres', 'minPrice', 'minSquareFootage'],
      in: ['status', 'propertySubTypeIds'],
      lte: ['maxAcres', 'maxPrice', 'maxSquareFootage'],
      or_and_places: ['counties', 'places', 'schoolDistricts', 'zip'],
      or_eq_basement: [
        'hasBasementTypeFinished',
        'hasBasementTypePartiallyFinished',
        'hasBasementTypeUnfinished'
      ],
      or_eq_heat: [
        'hasHeatTypeElectric',
        'hasHeatTypeForcedAir',
        'hasHeatTypeGas',
        'hasHeatTypeGeothermal',
        'hasHeatTypeGravity',
        'hasHeatTypeHeatPump',
        'hasHeatTypeSteam'
      ],
      or_search_type: ['showOnlyNewConstructions', 'showOnlyOpenHouses']
    };

    const transformations = {
      bathrooms: 'bathroomCount',
      bedrooms: 'bedroomCount',
      counties: 'county',
      maxAcres: 'acres',
      maxPrice: 'price',
      maxSquareFootage: 'squareFootage',
      minAcres: 'acres',
      minPrice: 'price',
      minSquareFootage: 'squareFootage',
      places: 'city',
      propertySubTypeIds: 'propertySubTypeId',
      schoolDistricts: 'schoolDistrict',
      showOnlyNewConstructions: 'isNewConstruction',
      showOnlyOpenHouses: 'hasInRangeOpenHouse',
      featured: 'isIdxListing'
    };

    const validOperatorsArray = Object.values(operators)
      .reduce((acc, currentArray) => [...acc, ...currentArray], [])
      .map((value) => transformations[value] || value);

    const keys = Object.keys(params);

    const and = [];
    const or_and_places_arr = [];
    const or_eq_basement = [];
    const or_eq_heat = [];
    const or_search_type = [];

    for (const el of keys) {
      const value = params[el];
      if (!value || (Array.isArray(value) && value.length < 1)) continue;
      const key = transformations[el] || el;
      const operator = Object.keys(operators).find((k) => operators[k].includes(el));

      switch (operator) {
        case 'or_and_places':
          value.forEach((val) => {
            or_and_places_arr.push({
              and: [{ [key]: { eq: val.location } }, { state: { eq: val.state } }]
            });
          });
          break;
        case 'or_eq_basement':
          or_eq_basement.push({ [key]: { eq: value } });
          break;
        case 'or_eq_heat':
          or_eq_heat.push({ [key]: { eq: value } });
          break;
        case 'and_since_new':
          or_search_type.push({
            and: [
              { daysSinceNew: { lte: value } },
              { daysSinceNew: { gte: 0 } },
              { alerts: { some: { type: { eq: 'ListedOn ' } } } }
            ]
          });
          break;
        case 'and_since_price_change':
          or_search_type.push({
            and: [
              { daysSinceLastPriceChange: { lte: value } },
              { alerts: { some: { type: { eq: 'priceChange' } } } }
            ]
          });
          break;
        case 'and_since_sold':
          and.push({
            and: [{ daysSinceSold: { lte: parseInt(value) } }, { daysSinceSold: { gte: 0 } }]
          });
          break;
        case 'and_is_featured':
          and.push({
            and: [{ isIdxListing: { eq: false } }]
          });
          break;
        case 'or_search_type':
          or_search_type.push({
            [key]: { eq: value }
          });
          break;
        case 'ignore':
          break;
        default:
          if (validOperatorsArray.includes(key)) {
            and.push({ [key]: { [operator]: value } });
          }
          break;
      }
    }

    if (or_and_places_arr.length > 0) and.push({ or: or_and_places_arr });
    if (or_eq_heat.length > 0) and.push({ or: or_eq_heat });
    if (or_eq_basement.length > 0) and.push({ or: or_eq_basement });
    if (or_search_type.length > 0) and.push({ or: or_search_type });
    and.push({ statusId: { neq: 14 } });

    return { and };
  }
}
